/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { App, Button, Checkbox, Form, Select } from 'antd'
import { debounce, random, uniqBy } from 'lodash'
import {
  suplyFlags,
  suplyTypes,
} from 'pages/ReportRegisterPage/components/data'
import React, { useContext, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { AppContext } from '../../components/AppContext'
import CustomSelect from '../../components/form/Select'
import Header from '../../components/Header'
import PageContainer from '../../components/PageContainer'
import { GetCompnayInfoBcncResponse } from '../../models/interfaces'
import { getCompanyInfoBcnc, postReportStar } from '../../service/repository'
import { getInitialForm, ReportStarForm } from './components/date'

interface Props {}

const ReportStarRegisterPage: React.FC<Props> = () => {
  const { message } = App.useApp()
  const { user } = useContext(AppContext)
  const navigate = useNavigate()

  const [isShowValidation, setIsShowValidation] = useState(false)

  const [getCompanyInfoBcncResponse, setGetCompanyInfoBcncResponse] =
    useState<GetCompnayInfoBcncResponse>({
      page: 0,
      pageSize: 0,
      totalElements: 0,
      items: [],
    })
  const { items: companies } = getCompanyInfoBcncResponse

  const [getAllCompanyInfoBcncResponse, setGetAllCompanyInfoBcncResponse] =
    useState<GetCompnayInfoBcncResponse>({
      page: 0,
      pageSize: 0,
      totalElements: 0,
      items: [],
    })
  const { items: allCompanies } = getAllCompanyInfoBcncResponse

  const initialForm = getInitialForm()

  const [form, setForm] = useState<ReportStarForm>(initialForm)

  const debounceFetcher = useMemo(() => {
    const loadCompanyInfoBcnc = async (value: string) => {
      const { data, error } = await getCompanyInfoBcnc(user.id, {
        companyName: value,
      })

      if (error) {
        const errorResponse = await error.context.json()
        if (errorResponse?.fromUdiSystem?.message) {
          message.error('Failed: ' + errorResponse.fromUdiSystem.message)
          return
        }

        message.error('Failed: ' + error.message)
        return
      }

      setGetCompanyInfoBcncResponse(data)
      setGetAllCompanyInfoBcncResponse((prev) => ({
        ...data,
        items: uniqBy([...data.items, ...prev.items], 'bcncCode'),
      }))
    }

    return debounce(loadCompanyInfoBcnc, 500)
  }, [])

  return (
    <StyledDiv>
      <Header
        onClickBackButton={() => navigate('/reports/register')}
        title="즐겨찾기 추가"
        companyName={user.companyName}
      />

      <PageContainer>
        <StyledCardContainer>
          <StyledCard>
            <Form layout="vertical">
              <CustomSelect
                valueList={suplyFlags}
                label="공급 구분"
                value={form.suplyFlagCode}
                validateStatus={
                  isShowValidation && !form.suplyFlagCode ? 'error' : 'success'
                }
                onClick={(value) => {
                  setForm({
                    ...form,
                    suplyFlagCode: value,
                  })
                }}
              />
              <CustomSelect
                valueList={suplyTypes}
                value={form.suplyTypeCode}
                validateStatus={
                  isShowValidation &&
                  (form.suplyFlagCode === '1' || form.suplyFlagCode === '4') &&
                  !form.suplyTypeCode
                    ? 'error'
                    : 'success'
                }
                label="공급 형태"
                onClick={(value) => {
                  console.log('콘솔 ', value)
                  setForm({
                    ...form,
                    suplyTypeCode: value,
                  })
                }}
              />
              <StyledFormItem
                validateStatus={
                  isShowValidation &&
                  (form.suplyFlagCode === '1' || form.suplyFlagCode === '2') &&
                  !form.bcncCode
                    ? 'error'
                    : 'success'
                }
                label="업체(기관)명"
              >
                <Select
                  showSearch
                  value={form.bcncCode}
                  defaultActiveFirstOption={false}
                  suffixIcon={null}
                  filterOption={false}
                  onSearch={debounceFetcher}
                  onChange={(value) => setForm({ ...form, bcncCode: value })}
                  notFoundContent={null}
                  options={companies.map((d) => ({
                    value: d.bcncCode,
                    label: `${d.companyName} (${d.bossName})`,
                  }))}
                />
              </StyledFormItem>

              <StyledCheckboxContainer>
                <div>납품업체와 장소가 다를 경우</div>
                <Checkbox
                  checked={form.isDiffDvyfg}
                  onChange={(e) =>
                    setForm({ ...form, isDiffDvyfg: e.target.checked })
                  }
                />
              </StyledCheckboxContainer>

              <StyledFormItem
                validateStatus={
                  isShowValidation &&
                  form.suplyFlagCode === '1' &&
                  form.isDiffDvyfg &&
                  !form.dvyfgPlaceBcncCode
                    ? 'error'
                    : 'success'
                }
                label="납품장소"
              >
                <Select
                  disabled={!form.isDiffDvyfg}
                  showSearch
                  value={form.dvyfgPlaceBcncCode}
                  defaultActiveFirstOption={false}
                  suffixIcon={null}
                  filterOption={false}
                  onSearch={debounceFetcher}
                  onChange={(value) =>
                    setForm({ ...form, dvyfgPlaceBcncCode: value })
                  }
                  notFoundContent={null}
                  options={companies.map((d) => ({
                    value: d.bcncCode,
                    label: `${d.companyName} (${d.bossName})`,
                  }))}
                />
              </StyledFormItem>
            </Form>

            <StyledButtonContainer>
              <Button
                type="primary"
                onClick={async () => {
                  setIsShowValidation(true)

                  if (
                    form.suplyFlagCode === '' ||
                    form.suplyTypeCode === '' ||
                    form.bcncCode === ''
                  ) {
                    return
                  }

                  const bncn = allCompanies.find(
                    (d) => d.bcncCode === form.bcncCode,
                  )
                  const dvyfgPlaceBcnc = allCompanies.find(
                    (d) => d.bcncCode === form.dvyfgPlaceBcncCode,
                  )

                  if (
                    (form.bcncCode && !bncn) ||
                    (form.dvyfgPlaceBcncCode && !dvyfgPlaceBcnc)
                  ) {
                    return
                  }

                  const { error } = await postReportStar(user.id, {
                    ...form,
                    id: random(0, 999999999).toString(),
                    title: `${bncn?.companyName} (${bncn?.bossName})`,
                    dvyfgPlaceBcncName: `${dvyfgPlaceBcnc?.companyName} (${dvyfgPlaceBcnc?.bossName})`,
                  })

                  if (error) {
                    message.error('Failed: ' + error.message)
                    return
                  }

                  message.success('즐겨찾기 추가 성공')
                  navigate('/reports/register')
                }}
              >
                추가
              </Button>
            </StyledButtonContainer>
          </StyledCard>
        </StyledCardContainer>
      </PageContainer>
    </StyledDiv>
  )
}

const StyledCardContainer = styled.div`
  margin-top: 16px;
`

const StyledCard = styled.div`
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  padding: 16px;
`

const StyledButtonContainer = styled.div`
  text-align: right;
`

const StyledCheckboxContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`

export const StyledFormItem = styled(Form.Item)`
  margin-bottom: 8px;
`

const StyledDiv = styled.div`
  overflow: scroll;
`

export default ReportStarRegisterPage
